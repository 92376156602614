import { Dictionary } from "ramda";
import { AccAddress } from "@hexxagon/terra.js";
import { Tokens } from "../hooks/cw20/useTokenBalance";
import {
  IBCTokenList,
  useContracts,
  useIBCWhitelist,
  useWhitelist
} from "../hooks/useTerraAssets";
import format from "../scripts/format";
import useDenomTrace from "../hooks/useDenomTrace";
import { useCurrentChain, useIsClassic } from "../contexts/ChainsContext";

type Props = {
  estimated?: boolean;
  fontSize?: number;
  className?: string;
  denom?: string;
  children?: string;
  decimals?: number;
};

export type Contract = Dictionary<Contracts>;

export const renderDenom = (
  str: string,
  chainID: string,
  whitelist?: Tokens,
  contracts?: Contract,
  ibcWhitelist?: IBCTokenList,
  isClassic?: boolean
) => {
  const list = whitelist?.[`${chainID}:${str}`];
  const contract = contracts?.[str];
  const ibc = ibcWhitelist?.[`${chainID}:${str}`];

  if (
    (AccAddress.validate(str) || str.startsWith("ibc")) &&
    (list || contract || ibc)
  ) {
    const symbol = list?.symbol || contract?.name || ibc?.symbol;
    return symbol;
  } else if (format.denom(str).length >= 40) {
    return "Token";
  } else {
    return format.denom(str, isClassic);
  }
};

const Amount = (props: Props) => {
  const { estimated, fontSize, className, denom, children, decimals } = props;
  const whitelist = useWhitelist();
  const contracts = useContracts();
  const ibcWhitelist = useIBCWhitelist();
  const isClassic = useIsClassic();
  const { chainID } = useCurrentChain();

  const tokenDecimals =
    whitelist?.[`${chainID}:${denom}` ?? ""]?.decimals ??
    ibcWhitelist?.[`${chainID}:${denom}` ?? ""]?.decimals;

  const [integer, decimal] = format
    .amount(children || "0", tokenDecimals ?? decimals)
    .split(".");

  const data = useDenomTrace(denom);

  return (
    <span className={className} style={{ fontSize }}>
      {estimated && "≈ "}
      {integer}
      <small>
        .{decimal}
        {data
          ? ` ${format.denom(data.base_denom, isClassic)}`
          : denom &&
            ` ${renderDenom(
              denom,
              chainID,
              whitelist,
              contracts,
              ibcWhitelist,
              isClassic
            )}`}
      </small>
    </span>
  );
};

export default Amount;
